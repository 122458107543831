import { StaticPage } from '~/components/Views/StaticPageView'

export default () => (
  <StaticPage
    title={'How Discours works'}
    desc={'A guide to horizontal editorial: how an open journal works'}
  >
    <p>
      Дискурс — независимый журнал о культуре, науке, искусстве и обществе с{' '}
      <a href="/manifest">открытой редакцией</a>. У нас нет главного редактора, инвестора и вообще никого,
      кто бы принимал единоличные решения. Вместо традиционных иерархий Дискурс основан на принципах прямой
      демократии: в нашем горизонтальном сообществе все редакционные вопросы решаются открытым голосованием
      авторов журнала. Вот как это работает.
    </p>
    <h3 id="how-it-works">Как устроен сайт Дискурса</h3>
    <p>Дискурс состоит из четырех основных разделов:</p>
    <ul>
      <li>
        <p>
          <a href="/topic">Темы</a> — у нас публикуются исследования, обзоры, эссе, интервью, репортажи,
          аналитика и другие материалы о культуре, науке, искусстве и обществе.
        </p>
      </li>
      <li>
        <p>
          <a href="/expo">Искусство</a> — здесь, например, представлены художественные произведения:
          литература, живопись, музыка, фотографии, видео. Этот раздел помогает прозвучать новому искусству,
          которое создают российские художники, писатели, режиссёры и музыканты.
        </p>
      </li>
      {/*
        <li>
      <p>
      <a href="/topic/events">События</a> — в этом разделе
      публикуются самые важные, по мнению редакции, культурные
      события России — выставки, лекции, концерты, кинопоказы, фестивали,
      художественные и политические акции. Напишите нам
      на <a href="mailto:welcome@discours.io" target="_blank">почту</a>, если вы
      хотите разместить объявление. Мы делаем это
      на безвозмездной основе.
      </p>
    </li >
        <li>
          <p>
            <a href="/edit/new" class="ng-scope" target="_blank">Редакция</a> —
            это внутренний раздел, где появляются новые материалы, которые присылают
            в редакцию. Здесь авторы обсуждают, редактируют и оценивают
            публикации, определяя таким образом содержание журнала.
          </p>
        </li>
*/}
    </ul>
    <p>
      Материалы в Дискурсе объединяются по <b>темам</b>— ключевым словам, которые располагаются в конце
      материалов и связывают материалы по жанрам (например, <a href="/topic/interview">интервью</a>,{' '}
      <a href="/topic/reportage">репортажи</a>, <a href="/topic/essay">эссе</a>,{' '}
      <a href="/topic/likbez">ликбезы</a>
      ), по тематике (<a href="/topic/cinema">кино</a>, <a href="/topic/philosophy">философия</a>,{' '}
      <a href="/topic/history">история</a>, <a href="/topic/absurdism">абсурдизм</a>,{' '}
      <a href="/topic/sex">секс</a> и т.д.) или в серии (как «<a href="/topic/zakony-mira">Законы мира</a>»
      или «<a href="/topic/za-liniey-mannergeyma">За линией Маннергейма</a>
      »). Темы объединяют сотни публикаций, помогают ориентироваться в журнале и следить за интересными
      материалами.
    </p>

    <section>
      <h3 id="become-author">Как стать автором журнала</h3>
      <p>
        Дискурс объединяет журналистов, активистов, музыкантов, художников, фотографов, режиссеров,
        философов, ученых и других замечательных людей. Каждый может <a href="/edit/new">прислать</a> свой
        материал в журнал. Формат и тематика не имеют значения, единственное, что важно —{' '}
        <a href="/how-to-write-a-good-article">хороший</a> ли материал. Если сообщество поддержит вашу
        публикацию, она выйдет в журнале и станет доступна тысячам наших читателей.
      </p>
    </section>

    <h3 id="voting">Как проходит голосование</h3>
    <p>
      Все присылаемые в Дискурс материалы попадают в <strong>«Редакцию»</strong>. Это внутренний раздел
      сайта, где участники сообщества решают, что будет опубликовано в Дискурсе. Как только работа получает
      одобрение как минимум пятерых авторов открытой редакции, она немедленно публикуется в журнале. Если же
      материал набирает более 20% голосов «против», он не выходит и может быть отправлен на доработку.
      Жестких сроков рассмотрения материалов у нас нет, иногда это занимает час, иногда месяц, обычно —
      несколько дней.
    </p>
    <section>
      <p>
        Как только сообщество поддержит публикацию, вы получите приглашение в интернет-редакцию и сможете
        голосовать за новые материалы.
      </p>
    </section>

    <h3 id="editing">Как мы делаем тексты друг друга лучше</h3>
    <p>
      Дискурс — журнал с совместным редактированием. Совершенствовать тексты нам помогает{' '}
      <b>система ремарок</b>. Вы можете выделить часть текста в любой статье и оставить к ней замечание,
      вопрос или предложение — автор текста получит совет на почту и сможет его учесть. Так мы устраняем
      опечатки, неточности и советуем друг другу, как сделать тексты качественнее и интереснее.
    </p>
    <p>
      Среди участников сообщества есть профессиональные редакторы, которые помогают авторам делать тексты
      лучше. Если вашему материалу потребуется доработка, они помогут отредактировать текст, подобрать
      иллюстрации, придумать заголовок и красиво сверстать публикацию. Если вы хотите обсудить текст, прежде
      чем загрузить материал в интернет-редакцию — разместите его в google-документе, откройте доступ к
      редактированию по ссылке и напишите нам на
      <a href="mailto:welcome@discours.io" target="_blank" rel="noreferrer">
        welcome@discours.io
      </a>
      .
    </p>
    <p>
      Если у вас возникают трудности с тем, чтобы подобрать к своему материалу иллюстрации, тоже пишите на{' '}
      <a href="mailto:welcome@discours.io" target="_blank" rel="noreferrer">
        почту
      </a>{' '}
      — наши коллеги-художники могут вам помочь{' '}
      <a href="/edit/new?collab" target="_blank" rel="noreferrer">
        в режиме совместного редактирования
      </a>
      .
    </p>

    <h3 id="perks">Что сообщество дает авторам</h3>
    <ul>
      <li>
        <p>
          <strong>Право определять, каким будет журнал</strong>. Дискурс — это общественная институция,
          созданная людьми и ради людей, функционирующая на условиях прямой демократии. Авторы публикуют
          статьи и художественные проекты, участвуют в обсуждениях, голосуют за работы коллег и таким
          образом вносят свой вклад в развитие проекта, определяя содержание и направление журнала.
        </p>
      </li>
      <li>
        <p>
          <strong>Возможность обратиться к широкой аудитории</strong>. Дискурс читают десятки тысяч людей, и
          с каждым днем их становится больше.
        </p>
      </li>
      <li>
        <p>
          <strong>Поддержка редакции</strong>. Дискурс предоставляет авторам аккредитацию на мероприятия,
          базу контактов, юридическую поддержку, ознакомление с книжными, кино- и музыкальными новинками до
          их выхода в свет. Если что-то из этого вам понадобится, пишите на почту{' '}
          <a href="mailto:welcome@discours.io" target="_blank" rel="noreferrer">
            welcome@discours.io
          </a>
          — поможем.
        </p>
      </li>
      <li>
        <p>
          <strong>Пресс-карты для корреспондентов</strong>. Три опубликованные статьи позволяют авторам
          Дискурса получить официальные удостоверения журналистов (пресс-карты) на следующий год.
          Пресс-карты удостоверяют, что вы журналист и можете пользоваться всеми теми правами, которые
          гарантирует Закон о СМИ. Кроме того, многие культурные институции (музеи, галереи и др.)
          предоставляют журналистам право свободного входа.
        </p>
      </li>
      <li>
        <p>
          <strong>Помощь сотен специалистов в разных областях</strong>. В основе Дискурса лежит идея
          совместного редактирования. Участники редакционного сообщества — несколько сотен журналистов,
          исследователей, художников, литераторов из разных стран — изучают материалы друг друга до
          публикации и помогают сделать их качественнее и интереснее. Так, в редакции нередко складываются
          творческие союзы: например, авторов текстов и художников, создающих для них иллюстрации.
        </p>
      </li>
      <li>
        <p>
          <strong>Пространство общения полное выдающихся людей</strong>. Дискурс — большое живое сообщество
          интеллектуалов, разбросанных по всему земному шару. Вступив в редакцию, вы сможете познакомиться
          со множеством интересных людей, которые определяют повестку завтрашнего дня, вдохновляют
          окружающих, создают новое и изучают старое, ищут знания и готовы ими делиться, чтобы менять мир в
          соответствии со своими идеалами.
        </p>
      </li>
    </ul>

    <h3 id="contacts">Как быть в курсе</h3>
    <p>
      За свежими публикациями Дискурса можно следить не только на сайте, но и на страницах в{' '}
      <a href="https://facebook.com/discoursio/" target="_blank" rel="noreferrer">
        Фейсбуке
      </a>
      ,{' '}
      <a href="https://vk.com/discoursio" target="_blank" rel="noreferrer">
        ВКонтакте
      </a>{' '}
      и{' '}
      <a href="https://t.me/discoursio" target="_blank" rel="noreferrer">
        Телеграме
      </a>
      . А ещё раз в месяц мы отправляем <a href="#subscribe">почтовую рассылку</a> с дайджестом лучших
      материалов.
    </p>
    <p>
      Если вы хотите сотрудничать, что-то обсудить или предложить — пожалуйста, пишите на{' '}
      <a href="mailto:welcome@discours.io" target="_blank" rel="noreferrer">
        welcome@discours.io
      </a>
      . Мы обязательно ответим.
    </p>
  </StaticPage>
)
